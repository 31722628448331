<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<MessageForm
							:id="id"
							is-update
							@apply-form="updateMessage"
							@cancel-form="goToMessageList"
							@delete-form="deleteMessage"
						></MessageForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { GET_MESSAGE_BY_ID, UPDATE_MESSAGE, DELETE_MESSAGE } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("messages")
export default {
	name: "UpdateMessage",

	components: {
		MessageForm: () => import("./../components/MessageForm.vue"),
	},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		this.GET_MESSAGE_BY_ID({ id: this.id })
	},

	methods: {
		...mapActions({ GET_MESSAGE_BY_ID, UPDATE_MESSAGE, DELETE_MESSAGE }),

		updateMessage: async function (params) {
			if (!params.id) params.id = this.id
			await this.UPDATE_MESSAGE(params)
		},

		deleteMessage: async function (params) {
			if (!params.id) params.id = this.id
			await this.DELETE_MESSAGE(params)
		},

		goToMessageList() {
			this.$router.push({ name: "MessageList" })
		},
	},
}
</script>

<style lang="scss" scoped></style>
